// .breadcrumbs > * {
//   display: inline-block;
//   margin-right: 5px;
//   text-decoration: none;

//   h3 {
//     text-decoration: none;
//     color: #6e757e;
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 130%; /* 19.5px */
//   }
// &:last-child a {
//   color: #000;

//   font-size: 15px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 130%; /* 19.5px */
//   cursor: pointer;
//   pointer-events: all;
// }
//   a {
//     text-decoration: none;
//     color: #6e757e;
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: 130%; /* 19.5px */
//     pointer-events: none;
//     text-transform: capitalize;
//     &::before {
//       content: "";
//       display: inline-block;
//       width: 10px;
//       height: 10px;
//       background-image: url("../../assets/icons/arrowRight.svg");
//       background-size: cover; /* Resmin boyutunu ayarlayın */
//       margin-right: 5px;
//     }
//   }
// }
.breadcrumb-component {
  @media screen and (max-width: 996px) {
    display: none;
  }
  &__inner {
    border-top: 0.5px solid #ccc;
    border-bottom: 0.5px solid #ccc;
    padding: 30px 0;
    margin: 20px 0 30px 0;
  }
  &--breadcrumb {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    &:last-child a {
      color: #6e757e;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      color: #000;
      &:last-child {
        span {
          display: inline-block;
          // max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      img {
        filter: invert(100%);
      }
    }
  }
}
