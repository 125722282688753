.navbar {
  .home-navbar {
    display: flex;
    padding-top: 30px;
    justify-content: space-between;
    align-items: center;
  }

  .burger-menu {
    display: none;
    cursor: pointer;
  }

  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-ul > li {
      display: inline-block;
      position: relative;
      margin-right: 50px;

      span {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        width: 100% !important;
      }
    }
  }
  .menu-ul > li.active {
    border-bottom: 2px solid #000; /* İstediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */
  }

  .sub-menu {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    border-radius: 12px;
    background-color: #fff;

    padding: 10px 15px;
    transition: top 0.3s ease;
    width: 250px;
    li {
      margin: 0 !important;
      a {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        line-height: 209.974%;
      }
    }
  }

  .sub-menu-ul {
    margin: 20px;
  }
  .sub-menu.open {
    display: block;
    z-index: 2;
    display: flex;
    gap: 10px;
    flex-direction: column;
    li {
      a {
        &:hover {
          color: #144e8c;
          transition: 0.3s ease;
        }
      }
    }
  }

  .home-navbar__contact {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 996px) {
      display: none;
    }
    &__information {
      p {
        color: #000;
        text-align: right;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      h2 {
        color: #000;
        text-align: right;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &__phone {
        display: flex;
        img {
          padding: 0 6px 2px 0;
        }
      }
    }
  }
}
.menu-header {
  display: none;
}

@media screen and (max-width: 996px) {
  .navbar .burger-menu {
    display: block;
  }

  .home-navbar {
    margin-bottom: 50px;
  }
  .navbar .menu {
    display: none;
  }
  .menu-header {
    display: block;
  }

  .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    border-radius: 0;
    background-color: transparent !important;
    padding: 30px;
    overflow: auto;
    transition: top 0.3s ease;
    z-index: 1;
    background-color: red;
    ul {
      margin: 20px 10px;
    }
    li {
      margin: 0;
      a {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        line-height: 209.974%;
      }
    }
  }

  .navbar .menu.open {
    display: block;
    flex-direction: column;

    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #e8edf4;
    padding-top: 20px;
    z-index: 999;
    animation: slideIn 0.5s ease-in-out;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    .menu-ul {
      margin-top: 100px;
      margin-left: 40px;
    }
  }

  .close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .close-menu img {
    width: 24px;
    height: 24px;
  }

  .navbar .menu.open li {
    display: block;
    margin-bottom: 20px;
    margin-right: 0;
  }
}
.test {
  display: flex;
  justify-content: center;
}

.menuArrowIcon {
  width: 14px !important;
  height: 14px !important;
  transition: transform 0.6s !important;
}

.rotate {
  transform: rotate(180deg) !important;
  transition: transform 0.6s !important;
}
