.trafficInsurance {
  &__productCard {
    margin-top: 80px;
  }
}

.traffic-insurance {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .traffic-insurance_information {
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &_bg {
      margin-bottom: 30px;
      img {
        max-width: 100%;
      }
    }
    &_text {
      max-width: 493px;
      h1 {
        color: #000;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 25px;

        @media screen and (max-width: 1148px) {
          font-size: 22px;
        }
      }
      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 175%;

        color: #000;
      }
      ul {
        li {
          position: relative;
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%;
          padding-left: 1em;
          &::before {
            content: "\2022";
            position: absolute;
            left: 0;
            color: #000;
          }
          @media screen and (max-width: 1148px) {
            font-size: 15px;
          }
        }
      }
    }
  }
  .traffic-insurance_form {
    border-radius: 18px;
    background: #fff;
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
    .customInput {
      width: 100%;
    }

    p {
      color: #515353;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &__content {
      margin: 20px 0;

      &__body {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        @media screen and (max-width: 635px) {
          grid-template-columns: 1fr;
        }
      }
    }
    &__kvkk {
      display: flex;
      gap: 10px;
      p {
        color: #515353;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        a {
          color: #1677ff;
          cursor: pointer;
        }
      }
    }
  }
}
