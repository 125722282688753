.custom-date-picker {
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.04em;
  }

  .ant-picker {
    height: 60px;
    font-style: normal;
    color: #515353;
    font-size: 15px;
    font-weight: 500;
    border: 0.5px solid #515353;
    border-radius: 12px; // background-color: #f8f8f8;
  }
  .ant-picker-input > input {
    padding-left: 10px;
    &::placeholder {
      color: #515353;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .ant-picker-input > input[disabled] {
    color: rgba(0, 0, 0, 0.75);
  }

  .ant-picker-disabled {
    background-color: #e8e8e8 !important;
  }
}
