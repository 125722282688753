.custom-select {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .disabled {
    opacity: 0.5;
  }

  label {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.04em;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 60px;
    background-color: unset;
    border: unset;
  }

  .ant-select {
    height: 60px;
    border-radius: 10px;
    background-color: #fff;
    border: 0.5px solid #515353;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .ant-select-selector {
    background-color: #f8f8f8;
    height: 50px;
    border: none;

    /* Selector focus durumunda outline'ı kaldır */
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:hover,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .ant-select-selection-placeholder {
    display: flex;
    color: #515353;
    font-size: 15px;
    font-weight: 500;
    align-items: center;
    padding-left: 10px !important;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    padding-left: 10px !important;
  }

  &__error {
    color: red;
    display: none;
    font-size: 14px;
  }

  [aria-invalid="true"] ~ &__error {
    display: block;
  }
}
