.layout {
  display: flex;
  flex-direction: column;
  &_body {
    background: linear-gradient(
      180deg,
      rgba(21, 78, 140, 0.1) 36.94%,
      rgba(21, 78, 140, 0) 100%
    );
  }
}

// /* Navbar */
// .navbar {
//   background-color: #333;
//   color: #fff;
//   padding: 10px 20px;
// }

// .navbar ul {
//   list-style-type: none;
//   padding: 0;
//   margin: 0;
// }

// .navbar ul li {
//   display: inline-block;
//   margin-right: 20px;
// }

// .navbar ul li a {
//   color: #fff;
//   text-decoration: none;
// }

// /* Active link style */
// .navbar ul li a.active {
//   font-weight: bold;
// }

// /* Topbar */
// .topbar {
//   background-color: #ccc;
//   padding: 10px 20px;
// }

/* Outlet content */
.outlet-content {
  padding: 20px;
}
