.kvkkContent {
  h1 {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    color: #313742;
    font-size: 15px;
    font-weight: 400;
  }
}
