// @import "../node_modules/antd/dist/antd.min.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  padding: 0;
  margin: 0;
  list-style: none;
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  // background: linear-gradient(
  //   180deg,
  //   rgba(21, 78, 140, 0.1) 36.94%,
  //   rgba(21, 78, 140, 0) 100%
  // );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

@keyframes globalSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

.global-loading {
  display: grid;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40vh);
  .loading-spinner {
    width: 48px;
    height: 48px;
    border: 2px solid #fff; /* Light grey */
    border-top: 2px solid #383636; /* Blue */
    border-radius: 50%;
    animation: globalSpinner 1.5s linear infinite;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 1100px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1440px;
  }
}

// .ant-spin-dot-item {
//   background-color: #0f3e6d;
// }

// @font-face {
//   font-family: "Satoshi-Variable";
//   src: url("../src/assets/fonts/Satoshi-Variable.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Variable.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Variable.ttf") format("truetype");
//   font-weight: 300 900;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Satoshi-VariableItalic";
//   src: url("../src/assets/fonts/Satoshi-VariableItalic.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-VariableItalic.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-VariableItalic.ttf") format("truetype");
//   font-weight: 300 900;
//   font-display: swap;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Satoshi-Light";
//   src: url("../src/assets/fonts/Satoshi-Light.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Light.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Light.ttf") format("truetype");
//   font-weight: 300;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Satoshi-LightItalic";
//   src: url("../src/assets/fonts/Satoshi-LightItalic.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-LightItalic.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-LightItalic.ttf") format("truetype");
//   font-weight: 300;
//   font-display: swap;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Satoshi-Regular";
//   src: url("../src/assets/fonts/Satoshi-Regular.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Regular.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Regular.ttf") format("truetype");
//   font-weight: 400;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Satoshi-Italic";
//   src: url("../src/assets/fonts/Satoshi-Italic.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Italic.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Italic.ttf") format("truetype");
//   font-weight: 400;
//   font-display: swap;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Satoshi-Medium";
//   src: url("../src/assets/fonts/Satoshi-Medium.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Medium.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Satoshi-MediumItalic";
//   src: url("../src/assets/fonts/Satoshi-MediumItalic.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-MediumItalic.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-MediumItalic.ttf") format("truetype");
//   font-weight: 500;
//   font-display: swap;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Satoshi-Bold";
//   src: url("../src/assets/fonts/Satoshi-Bold.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Bold.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Bold.ttf") format("truetype");
//   font-weight: 700;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Satoshi-BoldItalic";
//   src: url("../src/assets/fonts/Satoshi-BoldItalic.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-BoldItalic.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-BoldItalic.ttf") format("truetype");
//   font-weight: 700;
//   font-display: swap;
//   font-style: italic;
// }

// @font-face {
//   font-family: "Satoshi-Black";
//   src: url("../src/assets/fonts/Satoshi-Black.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-Black.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-Black.ttf") format("truetype");
//   font-weight: 900;
//   font-display: swap;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Satoshi-BlackItalic";
//   src: url("../src/assets/fonts/Satoshi-BlackItalic.woff2") format("woff2"),
//     url("../src/assets/fonts/Satoshi-BlackItalic.woff") format("woff"),
//     url("../src/assets/fonts/Satoshi-BlackItalic.ttf") format("truetype");
//   font-weight: 900;
//   font-display: swap;
//   font-style: italic;
// }

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.custom-list {
  max-height: 250px;
  overflow-y: auto;
  padding-bottom: 20px;
}

.errorTextCommon {
  color: red;
  font-size: 14px;
}

.ant-modal-content {
  border-radius: 20px;
}
