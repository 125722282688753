.ProductsInfo {
  padding-bottom: 70px;
  h1 {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }

  &__card {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 636px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
    &__inner {
      text-align: center;
      p {
        color: #313742;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        max-width: 355px; /* Metin genişliği 355px ile sınırlandırıldı */
        margin: 0 auto; /* Ortalamak için otomatik kenar boşluğu */
      }
      h2 {
        padding: 30px 0 15px 0;
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
