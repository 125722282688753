.contact {
  margin-bottom: 80px;
  &__map {
    margin-bottom: 30px;
    .leaflet-container {
      border-radius: 18px;
    }
  }
  &__information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media screen and (max-width: 1185px) {
      grid-template-columns: 1fr;
    }
    &__card {
      border-radius: 18px;
      background: #fff;
      padding: 30px;
      gap: 30px;
      &__contact {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 50px;
      }
      &__number {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 597px) {
          grid-template-columns: 1fr;
        }
        &__inner {
          display: flex;
          .min-width-45 {
            min-width: 45px;
            @media screen and (max-width: 597px) {
              min-width: 95px;
            }
            @media screen and (max-width: 365px) {
              min-width: 83px;
            }
          }
          .min-width-50 {
            min-width: 50px;
            @media screen and (max-width: 597px) {
              min-width: 95px;
            }
            @media screen and (max-width: 365px) {
              min-width: 83px;
            }
          }
          h4 {
            color: #000;
            font-size: 15px;
            font-weight: 600;
            line-height: 175%;
            position: relative;
            min-width: 95px;
            margin-right: 5px;
            &::after {
              content: ":";
              position: absolute;
              right: 0;
            }
            @media screen and (max-width: 363px) {
              min-width: 83px;
              font-size: 13px;
            }
          }
          p {
            color: #000;
            font-size: 15px;
            font-weight: 400;
            line-height: 175%;
            @media screen and (max-width: 363px) {
              font-size: 13px;
            }
          }
        }
      }
      &__contact {
        h2 {
          color: #000;
          font-size: 26px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        h3 {
          color: #000;
          font-size: 18px;
          font-weight: 600;
          line-height: 175%;
          margin-bottom: 5px;
        }
        p {
          span {
            position: relative;
            font-weight: 600;
            &::after {
              content: ":";
              position: absolute;
              right: 0;
            }
          }
          color: #000;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%;
        }
      }
    }
  }

  &__form {
    p {
      color: #515353;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 20px;
      margin-left: 45px;
      @media screen and (max-width: 540px) {
        margin-left: 3px;
      }
    }
    &__content {
      &__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 10px;
        @media screen and (max-width: 584px) {
          grid-template-columns: 1fr;
        }
      }
      &__kvkk {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        p {
          color: #515353;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          margin: 0;
          a {
            color: #1677ff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.map-wrapper {
  width: 100%; /* Veya istediğiniz genişlik değeri */
  height: 400px; /* Veya istediğiniz yükseklik değeri */
}
