button {
  color: #ffffff;
  outline: none;
  cursor: pointer;
  border: none;
  position: relative;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.04em;
  padding: 21px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  img {
    margin-right: 5px;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 16px;
    height: 16px;
    border: 1px solid #fff; /* Light grey */
    border-top: 1px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .loading {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin-left: 20px;
  }
}
.primary-btn {
  background-color: #003f6d;
  &:hover {
    background-color: #002f4f;
  }
}
.secondary-btn {
  background-color: #8293a2;
  &:hover {
    background-color: #7f8a92;
  }
}

.error-btn {
  background-color: #c40000;
  &:hover {
    background-color: #9b0000;
  }
}

.cart-btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 60px;
  &:hover {
    background-color: #fff;
    color: #000;
    cursor: pointer;
    img {
      filter: invert(100%);
    }
  }
}
