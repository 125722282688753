.footer {
  background: rgba(21, 78, 140, 0.03);
  &-border {
    position: relative;
  }
  &__acentimeLogo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 36px;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    @media screen and (max-width: 996px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    img {
      max-width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.5px;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &__acentimeContact {
    display: flex;
    justify-content: space-between;
    padding: 34px 0;
    img {
      max-width: 100%;
    }
    @media screen and (max-width: 356px) {
      display: inline-block;

      p {
        text-align: left !important;
        margin-top: 10px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.5px;
      color: rgba(0, 0, 0, 0.2);
    }

    &__information {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.5px;
        background-color: rgba(0, 0, 0, 0.2);
      }
      p {
        color: #000;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      h2 {
        color: #000;
        text-align: right;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &__phone {
        display: flex;
        gap: 6px;
      }
    }
  }
  &__products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 30px 0 20px 0;
    @media screen and (max-width: 916px) {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.5px;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &__inner {
      h1 {
        color: #000;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
      }
      &--menu {
        color: #5f5f5f;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 209.974%;
        a {
          color: #5f5f5f;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 209.974%;
        }
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__info {
    p {
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 209.974%;
      padding: 10px;
    }

    display: flex;
    justify-content: center;
  }
}
.footer-wrapper {
  position: relative; /* Dış <div> içindeki mutlak pozisyonlu elementlerin referansını almak için */
}
