.home {
  margin-top: 68px;
  &__inner {
    margin-bottom: 60px;
  }
  &__top {
    display: grid;
    grid-template-columns: 1.2fr 2fr;
    align-items: center;
    position: relative;
    padding-bottom: 80px;
    @media screen and (max-width: 920px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.5px;
      background-color: rgba(21, 78, 140, 0.2);
    }

    &__content {
      &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        span {
          color: #353d47;
          font-size: 48px;
          font-weight: 600;
          // line-height: 130%;
        }
        img {
          max-width: 100%;
        }
        // img {
        //   padding: 0 10px;

        // }
      }

      p {
        color: #353d47;
        font-size: 26px;
        font-weight: 400;
        line-height: 130%;
      }
    }
    &__logo {
      max-width: 100%;
      display: flex;
      justify-content: end;
      img {
        max-width: 100%;
      }
    }
  }
  &__bottom {
    gap: 20px;
    margin-top: 60px;
    &__row-top {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 375px) {
        grid-template-columns: 1fr;
      }
    }
    &__row-bottom {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }
    &__card {
      border-radius: 20px;
      background: #e8edf4;
      padding: 36px 0;
      text-align: center;
      h3 {
        color: #353d47;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 18.2px */
        margin-top: 20px;
      }
    }
  }
}
