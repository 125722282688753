.custom-text-area {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    gap: 10px;
    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    span {
      font-size: 12px;
    }
  }

  .ant-input {
    resize: none;
    // background-color: #f8f8f8;
    border: 0.5px solid #515353 !important;
    outline: none;
    border-radius: 12px !important;
    padding: 10px 15px;
    box-shadow: none !important;
    :focus {
      box-shadow: none;
      outline: 1px solid #003f6d !important;
    }
    &:disabled {
      background-color: #e8e8e8;
    }
  }

  .ant-input-affix-wrapper {
    border: none !important;
    box-shadow: none !important;
  }

  /* focus durumunda border olmaması */
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
}
