.inputBoxWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__count {
    position: absolute;
    right: 15px;
    bottom: 0;
    font-size: 12px;
    color: #888888;
  }
  &__tooltip {
    position: absolute;
    right: 4%;
    top: 28%;
    transform: translateY(25%);
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
  label {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    gap: 10px;
    div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    span {
      font-size: 12px;
    }
  }

  input {
    &:focus {
      // max-height: 50px;
      outline: 1px solid #003f6d !important;
      // box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    }
  }
  .editMode {
    position: absolute;
    right: 15px;
    top: calc(49%);
    padding: 3px;
    font-size: 13px;
    margin-bottom: 10px;
    @media screen and (max-width: 920px) {
      top: 0;
    }
  }
  [aria-invalid="true"] ~ .inputBoxErrorContent {
    display: block;
  }
  [aria-invalid="true"] ~ input {
    outline: 1px solid red !important;
  }
}

.inputbox {
  border: none;
  outline: 1px solid gray;
}
.inputError {
  outline: 1px solid red !important;
}
.inputBoxErrorContent {
  font-size: 14px;
  color: red;
  display: none;
  position: relative;
}

.customInput {
  background-color: #fff;
  padding: 21px 20px;
  height: 60px;
  border-radius: 12px;
  border: 0.5px solid #515353;
  outline: none;
  &::placeholder {
    color: #515353;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.75);
    background-color: #e8e8e8;
    cursor: not-allowed;
  }
}

.infoInput {
  position: relative;
}
.popup {
  position: absolute;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 15px 30px 30px;
  // width: 314px;
  // height: 133px;
  z-index: 2;
  bottom: 60px;
  right: -50px;

  &-content {
    display: flex;
    gap: 26px;

    p {
      margin: 0 !important;
      width: 179px;
    }
  }
}
